import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import affiliateReducer from "./slices/affiliate";
import calendarReducer from "./slices/calendar";
import capitalizerReducer from "./slices/capitalizer";
import chatReducer from "./slices/chat";
import faqReducer from "./slices/faq";
import kanbanReducer from "./slices/kanban";
import mailReducer from "./slices/mail";
import orderReducer from "./slices/order";
import productReducer from "./slices/product";
import settingsReducer from "./slices/settings";
import socialMediasReducer from "./slices/socialMedias";
import transactionReducer from "./slices/transaction";
import userReducer from "./slices/user";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const productPersistConfig = {
  key: "product",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy", "checkout"],
};

const userPersistConfig = {
  key: "user",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy", "checkout"],
};

const faqPersistConfig = {
  key: "faq",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy"],
};

const settingsPersistConfig = {
  key: "settings",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy"],
};

const socialMediasPersistConfig = {
  key: "socialMedias",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy"],
};

const orderPersistConfig = {
  key: "order",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy"],
};

const capitalizerPersistConfig = {
  key: "capitalizer",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy"],
};

const affiliatePersistConfig = {
  key: "affiliate",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy"],
};

const transactionPersistConfig = {
  key: "transaction",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy"],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  user: persistReducer(userPersistConfig, userReducer),
  faq: persistReducer(faqPersistConfig, faqReducer),
  settings: persistReducer(settingsPersistConfig, settingsReducer),
  socialMedias: persistReducer(socialMediasPersistConfig, socialMediasReducer),
  order: persistReducer(orderPersistConfig, orderReducer),
  capitalizer: persistReducer(capitalizerPersistConfig, capitalizerReducer),
  affiliate: persistReducer(affiliatePersistConfig, affiliateReducer),
  transaction: persistReducer(transactionPersistConfig, transactionReducer),
});

export { rootPersistConfig, rootReducer };
