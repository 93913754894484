// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  newPassword: path(ROOTS_AUTH, "/new-password"),
};

export const PATH_PAGE = {
  page403: "/403",
  page404: "/404",
  page500: "/500",
  components: "/components",
  affiliateExternalReference: `/prime/report/orders/external-reference?&page=1`,
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  products: {
    list: path(ROOTS_DASHBOARD, "/products"),
    // edit: (name) => path(ROOTS_DASHBOARD, `/products/${name}/edit`),
    edit: (name) => path(ROOTS_DASHBOARD, `/products/${name}/edit`),
    cadastro: path(ROOTS_DASHBOARD, "/products/new"),
    sorteioGiro: (name) => path(ROOTS_DASHBOARD, `/products/${name}/sorteio-giro`),
  },
  communications: {
    list: path(ROOTS_DASHBOARD, "/communications"),
    edit: (id) => path(ROOTS_DASHBOARD, `/communications/${id}/edit`),
    cadastro: path(ROOTS_DASHBOARD, "/communications/new"),
  },
  capitalizers: {
    list: path(ROOTS_DASHBOARD, "/capitalizers"),
    edit: (id) => path(ROOTS_DASHBOARD, `/capitalizers/${id}/edit`),
    new: path(ROOTS_DASHBOARD, "/capitalizers/new"),
    schedules: {
      new: (id) => path(ROOTS_DASHBOARD, `/capitalizers/${id}/schedules/new`),
      list: (id) => path(ROOTS_DASHBOARD, `/capitalizers/${id}/schedules`),
    },
  },
  affiliates: {
    list: path(ROOTS_DASHBOARD, "/affiliates"),
  },
  settings: {
    certificate: path(ROOTS_DASHBOARD, "/settings/certificate"),
    params: path(ROOTS_DASHBOARD, "/settings/params"),
    terms: path(ROOTS_DASHBOARD, "/settings/terms"),
    themes: path(ROOTS_DASHBOARD, "/settings/themes"),
    socialMedias: path(ROOTS_DASHBOARD, "/settings/social-medias"),
    analytics: path(ROOTS_DASHBOARD, "/settings/analytics"),
    faqs: {
      list: path(ROOTS_DASHBOARD, "/settings/faqs"),
      edit: (id) => path(ROOTS_DASHBOARD, `/settings/faqs/${id}/edit`),
      cadastro: path(ROOTS_DASHBOARD, "/settings/faqs/new"),
    },
    releaseNotes: path(ROOTS_DASHBOARD, "/settings/release-notes"),
  },
  transactions: {
    externalList: path(ROOTS_DASHBOARD, "/banking/transactions/external"),
  },
  general: {
    app: path(ROOTS_DASHBOARD, "/app"),
    ecommerce: path(ROOTS_DASHBOARD, "/ecommerce"),
    analytics: path(ROOTS_DASHBOARD, "/analytics"),
    banking: path(ROOTS_DASHBOARD, "/banking"),
    booking: path(ROOTS_DASHBOARD, "/booking"),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, "/mail"),
    all: path(ROOTS_DASHBOARD, "/mail/all"),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, "/chat"),
    new: path(ROOTS_DASHBOARD, "/chat/new"),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, "/calendar"),
  kanban: path(ROOTS_DASHBOARD, "/kanban"),
  permissionDenied: path(ROOTS_DASHBOARD, "/permission-denied"),
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    new: path(ROOTS_DASHBOARD, "/user/new"),
    list: path(ROOTS_DASHBOARD, "/user/list"),
    cards: path(ROOTS_DASHBOARD, "/user/cards"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    account: path(ROOTS_DASHBOARD, "/user/account"),
    bankAccount: (id) => path(ROOTS_DASHBOARD, `/user/${id}/bank-account`),
    edit: (id) => path(ROOTS_DASHBOARD, `/user/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, "/e-commerce"),
    shop: path(ROOTS_DASHBOARD, "/e-commerce/shop"),
    list: path(ROOTS_DASHBOARD, "/e-commerce/list"),
    checkout: path(ROOTS_DASHBOARD, "/e-commerce/checkout"),
    new: path(ROOTS_DASHBOARD, "/e-commerce/product/new"),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, "/e-commerce/product/nike-blazer-low-77-vintage/edit"),
    demoView: path(ROOTS_DASHBOARD, "/e-commerce/product/nike-air-force-1-ndestrukt"),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, "/invoice"),
    list: path(ROOTS_DASHBOARD, "/invoice/list"),
    listByProduct: (productId) => path(ROOTS_DASHBOARD, `/invoice/${productId}/orders`),
    new: path(ROOTS_DASHBOARD, "/invoice/new"),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, "/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit"),
    demoView: path(ROOTS_DASHBOARD, "/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5"),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, "/blog"),
    posts: path(ROOTS_DASHBOARD, "/blog/posts"),
    new: path(ROOTS_DASHBOARD, "/blog/new"),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, "/blog/post/apply-these-7-secret-techniques-to-improve-event"),
  },
};

export const PATH_DOCS = "https://docs-minimals.vercel.app/introduction";
