import { createSlice } from "@reduxjs/toolkit";

// utils
import axios from "../../utils/axios";

//
import { dispatch } from "../store";

const initialState = {
  isLoading: false,
  error: null,
  transactions: [],
  transaction: null,
  currentPage: 0,
  totalPages: 0,
  totalItems: 0,
};

const slice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getTransactionsSuccess(state, action) {
      state.isLoading = false;
      state.transactions = action.payload;
    },
  },
});

// Reducers
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getTransactions(page = 1, limit = 5, filters = {}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/banking/transactions", {
        params: {
          page: Number(page + 1),
          rowsPerPage: Number(limit),
          filters,
        },
      });
      dispatch(slice.actions.getTransactionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTransactionByTxIds(txIds, credentials) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/banking/transactions/external`, {
        params: {
          txIds,
          user: credentials.user,
          pass: credentials.pass,
        },
      });
      dispatch(slice.actions.getTransactionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
