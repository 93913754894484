// routes

import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import Iconify from "../../../components/Iconify";
import Label from "../../../components/Label";
import SvgIconStyle from "../../../components/SvgIconStyle";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  booking: getIcon("ic_booking"),
  invoice: getIcon("ic_invoice"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  menuItem: getIcon("ic_menu_item"),
  faq: <Iconify icon={"mdi:faq"} />,
  themes: <Iconify icon={"material-symbols:colors"} />,
  terms: <Iconify icon={"icon-park-outline:agreement"} />,
  social: <Iconify icon={"tabler:social"} />,
  settings: <Iconify icon={"mdi:cogs"} />,
  certificate: <Iconify icon={"carbon:certificate-check"} />,
  communications: <Iconify icon={"mdi:alert"} />,
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "geral",
    items: [
      {
        title: "início",
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard,
        roles: ["admin", "viewer"],
      },

      {
        title: "sorteios",
        path: PATH_DASHBOARD.products.list,
        icon: ICONS.ecommerce,
      },
      // AFFILIATE
      // {
      //    title: 'afiliados', path: PATH_DASHBOARD.affiliates.list, icon: ICONS.user,
      //    info: (<Label color="primary">novo</Label>)
      // },
      {
        title: "usuários",
        path: PATH_DASHBOARD.user.list,
        icon: ICONS.user,
        roles: ["admin"],
      },

      // { title: 'relatórios', path: PATH_DASHBOARD.invoice.root, icon: ICONS.analytics },

      // { title: 'configurações', path: PATH_DASHBOARD.user.account, icon: ICONS.kanban },

      // {
      //   title: "transações bancárias",
      //   path: PATH_DASHBOARD.transactions.list,
      //   icon: ICONS.banking,
      //   info: <Label color="primary">novo</Label>,
      // },

      // {
      //    title: 'usuários',
      //    path: PATH_DASHBOARD.user.root,
      //    icon: ICONS.user,
      //    children: [
      //       // { title: 'profile', path: PATH_DASHBOARD.user.profile },
      //       // { title: 'cards', path: PATH_DASHBOARD.user.cards },
      //       { title: 'lista', path: PATH_DASHBOARD.user.list },
      //       { title: 'criar', path: PATH_DASHBOARD.user.new },

      //       // { title: 'editar', path: PATH_DASHBOARD.user.demoEdit },
      //       // { title: 'meu perfil', path: PATH_DASHBOARD.user.account },
      //    ],
      // },

      // { title: 'ecommerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
      // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    ],
  },
  // // MANAGEMENT
  // // ----------------------------------------------------------------------
  // {
  //    subheader: 'management',
  //    items: [
  //       // USER
  //       // {
  //       //    title: 'user',
  //       //    path: PATH_DASHBOARD.user.root,
  //       //    icon: ICONS.user,
  //       //    children: [
  //       //       { title: 'Maiores compradores', path: PATH_DASHBOARD.user.cards },
  //       //       // { title: 'profile', path: PATH_DASHBOARD.user.profile },
  //       //       // { title: 'list', path: PATH_DASHBOARD.user.list },
  //       //       // { title: 'create', path: PATH_DASHBOARD.user.new },
  //       //       // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
  //       //       // { title: 'account', path: PATH_DASHBOARD.user.account },
  //       //    ],
  //       // },

  //       // // E-COMMERCE
  //       // {
  //       //    title: 'ecommerce',
  //       //    path: PATH_DASHBOARD.eCommerce.root,
  //       //    icon: ICONS.cart,
  //       //    children: [
  //       //       { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
  //       //       { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
  //       //       { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
  //       //       { title: 'create', path: PATH_DASHBOARD.eCommerce.new },
  //       //       { title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit },
  //       //       { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
  //       //    ],
  //       // },

  //       // // INVOICE
  //       // {
  //       //    title: 'invoice',
  //       //    path: PATH_DASHBOARD.invoice.root,
  //       //    icon: ICONS.invoice,
  //       //    children: [
  //       //       { title: 'list', path: PATH_DASHBOARD.invoice.list },
  //       //       { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
  //       //       { title: 'create', path: PATH_DASHBOARD.invoice.new },
  //       //       { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
  //       //    ],
  //       // },
  //    ],
  // },

  // SETTINGS
  {
    roles: ["admin"],
    subheader: "configurações",
    items: [
      {
        title: "capitalizadoras",
        path: PATH_DASHBOARD.capitalizers.list,
        icon: ICONS.banking,
        roles: ["admin"],
      },
      {
        title: "comunicados",
        path: PATH_DASHBOARD.communications.list,
        icon: ICONS.communications,
        roles: ["admin"],
      },
      {
        title: "perguntas frequentes",
        path: PATH_DASHBOARD.settings.faqs.list,
        icon: ICONS.faq,
        roles: ["admin"],
      },
      {
        title: "analytics",
        path: PATH_DASHBOARD.settings.analytics,
        icon: ICONS.analytics,
        // info: (<Label color="primary">novo</Label>)
        roles: ["admin"],
      },
      {
        title: "redes sociais",
        path: PATH_DASHBOARD.settings.socialMedias,
        icon: ICONS.social,
        roles: ["admin"],
      },
      {
        title: "certificado",
        path: PATH_DASHBOARD.settings.certificate,
        icon: ICONS.certificate,
        roles: ["admin"],
      },
      {
        title: "termos",
        path: PATH_DASHBOARD.settings.terms,
        icon: ICONS.terms,
        roles: ["admin"],
      },
      {
        title: "personalizar tema",
        path: PATH_DASHBOARD.settings.themes,
        icon: ICONS.themes,
        // info: (<Label color="primary">novo</Label>)
        roles: ["admin"],
      },
      {
        title: "parâmetros",
        path: PATH_DASHBOARD.settings.params,
        icon: ICONS.settings,
        roles: ["admin"],
      },
      {
        title: "notas de atualização",
        path: PATH_DASHBOARD.settings.releaseNotes,
        icon: ICONS.mail,
        info: <Label color="primary">novo</Label>,
      },
    ],
  },

  //    //       // BLOG
  //    //       {
  //    //          title: 'blog',
  //    //          path: PATH_DASHBOARD.blog.root,
  //    //          icon: ICONS.blog,
  //    //          children: [
  //    //             {title: 'posts', path: PATH_DASHBOARD.blog.posts },
  //    //             {title: 'post', path: PATH_DASHBOARD.blog.demoView },
  //    //             {title: 'create', path: PATH_DASHBOARD.blog.new },
  //    //          ],
  //    //       },
  //    //    ],
  //    // },

  // APP
  // ----------------------------------------------------------------------
  // {
  //    subheader: `app versao: ${APP_VERSION}`,
  //    items: [
  //       // {
  //       //    title: 'mail',
  //       //    path: PATH_DASHBOARD.mail.root,
  //       //    icon: ICONS.mail,
  //       //    info: <Label color="error">+32</Label>,
  //       // },
  //       // {title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //       // {title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //       // {title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban },
  //    ],
  // },

  //    // DEMO MENU STATES
  //    // {
  //    //    subheader: 'Other cases',
  //    //    items: [
  //    //       {
  //    //          // default roles : All roles can see this entry.
  //    //          // roles: ['user'] Only users can see this item.
  //    //          // roles: ['admin'] Only admin can see this item.
  //    //          // roles: ['admin', 'manager'] Only admin/manager can see this item.
  //    //          // Reference from 'src/guards/RoleBasedGuard'.
  //    //          title: 'item_by_roles',
  //    //          path: PATH_DASHBOARD.permissionDenied,
  //    //          icon: ICONS.menuItem,
  //    //          roles: ['admin'],
  //    //          caption: 'only_admin_can_see_this_item',
  //    //       },
  //    //       {
  //    //          title: 'menu_level_1',
  //    //          path: '#/dashboard/menu_level_1',
  //    //          icon: ICONS.menuItem,
  //    //          children: [
  //    //             {title: 'menu_level_2a', path: '#/dashboard/menu_level_1/menu_level_2a' },
  //    //             {
  //    //                title: 'menu_level_2b',
  //    //                path: '#/dashboard/menu_level_1/menu_level_2b',
  //    //                children: [
  //    //                   {
  //    //                      title: 'menu_level_3a',
  //    //                      path: '#/dashboard/menu_level_1/menu_level_2b/menu_level_3a',
  //    //                   },
  //    //                   {
  //    //                      title: 'menu_level_3b',
  //    //                      path: '#/dashboard/menu_level_1/menu_level_2b/menu_level_3b',
  //    //                      children: [
  //    //                         {
  //    //                            title: 'menu_level_4a',
  //    //                            path: '#/dashboard/menu_level_1/menu_level_2b/menu_level_3b/menu_level_4a',
  //    //                         },
  //    //                         {
  //    //                            title: 'menu_level_4b',
  //    //                            path: '#/dashboard/menu_level_1/menu_level_2b/menu_level_3b/menu_level_4b',
  //    //                         },
  //    //                      ],
  //    //                   },
  //    //                ],
  //    //             },
  //    //          ],
  //    //       },
  //    //       {title: 'item_disabled', path: '#disabled', icon: ICONS.menuItem, disabled: true },
  //    //       {
  //    //          title: 'item_label',
  //    //          path: '#label',
  //    //          icon: ICONS.menuItem,
  //    //          info: (
  //    //             <Label color="info" startIcon={<Iconify icon="eva:email-fill" />}>
  //    //                NEW
  //    //             </Label>
  //    //          ),
  //    //       },
  //    //       {
  //    //          title: 'item_caption',
  //    //          path: '#caption',
  //    //          icon: ICONS.menuItem,
  //    //          caption:
  //    //             'Quisque malesuada placerat nisl. In hac habitasse platea dictumst. Cras id dui. Pellentesque commodo eros a enim. Morbi mollis tellus ac sapien.',
  //    //       },
  //    //       {title: 'item_external_link', path: 'https://www.google.com/', icon: ICONS.menuItem },
  //    //    ],
  // },
];

export default navConfig;
