import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from "@mui/material";
// redux
import { useSnackbar } from "notistack";
import { getAllAffiliates } from "../../redux/slices/affiliate";
import { useDispatch, useSelector } from "../../redux/store";
// routes
// hooks
import useSettings from "../../hooks/useSettings";
import useTable, { emptyRows } from "../../hooks/useTable";

// components
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import Page from "../../components/Page";
import Scrollbar from "../../components/Scrollbar";
import { TableEmptyRows, TableHeadCustom, TableNoData } from "../../components/table";
// sections
import {
  AffiliateTableRow,
  AffiliateTableToolbar,
} from "../../sections/@dashboard/user/affiliates";

import DialogDownloading from "../../components/DialogDownloading";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Nome", align: "left" },
  { id: "phone", label: "Telefone", align: "left" },
  { id: "cpf", label: "CPF", align: "left" },
  { id: "affiliateCode", label: "Código", align: "left" },
  { id: "totalPaidAmount", label: "Faturamento", align: "left" },
  { id: "totalPaidQuantity", label: "Compras", align: "left" },
  { id: "status", label: "Status", align: "left" },
  { id: "" },
];

// ----------------------------------------------------------------------

export default function AffiliateList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { affiliates, isLoading, error } = useSelector((state) => state.affiliate);

  const [tableData, setTableData] = useState(affiliates);

  const [isDownloading, setIsDownloading] = useState(false);

  const [filters, setFilters] = useState({
    name: "",
    email: "",
    cpf: "",
    phone: "",
    affiliateCode: "",
  });

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(getAllAffiliates(filters));
  }, [dispatch, filters]);

  useEffect(() => {
    if (affiliates.length) {
      setTableData(affiliates);
    }
  }, [affiliates]);

  const handleFilterName = (filterName) => {
    setFilters({ ...filters, name: filterName });
    setPage(0);
  };

  const handleFilterCpf = (filterCpf) => {
    setFilters({ ...filters, cpf: filterCpf });
    setPage(0);
  };

  const handleFilterPhone = (filterPhone) => {
    setFilters({ ...filters, phone: filterPhone });
    setPage(0);
  };

  const handleFilterAffiliateCode = (filterAffiliateCode) => {
    setFilters({ ...filters, affiliateCode: filterAffiliateCode });
    setPage(0);
  };

  const handleEditRow = (id) => {
    navigate(`/dashboard/user/${id}/edit`);
  };

  const handleDownloadList = async () => {
    enqueueSnackbar("Em desenvolvimento...", { variant: "info" });

    // setIsDownloading(true);
    // try {
    //    const response = await axios.get(
    //       `/api/users/export`,
    //       {
    //          responseType: 'blob', // Defina o tipo de resposta como 'blob'
    //       }
    //    );

    //    const blob = new Blob([response.data], {
    //       type: 'text/csv',
    //    });
    //    const url = window.URL.createObjectURL(blob);

    //    const a = document.createElement('a');
    //    a.style.display = 'none';
    //    a.href = url;
    //    a.download = `relatorio-usuarios.csv`; // Defina o nome do arquivo

    //    document.body.appendChild(a);
    //    a.click();

    //    window.URL.revokeObjectURL(url); // Libere a URL temporária
    //    document.body.removeChild(a); // Remova o elemento <a> do DOM

    //    enqueueSnackbar('Relatório de usuários gerado com sucesso.', { variant: 'success' });
    // } catch (error) {
    //    setIsDownloading(false);
    //    console.error(error);
    //    enqueueSnackbar('Erro ao gerar relatório de usuários.', { variant: 'error' });
    // }
    // setIsDownloading(false);
  };

  const denseHeight = dense ? 52 : 72;

  const isNotFound = false;
  // (!dataFiltered.length && !!filterName) ||
  // (!dataFiltered.length && !!filterRole) ||
  // (!dataFiltered.length && !!filterStatus) ||
  // (!isLoading && !dataFiltered.length);

  return (
    <Page title="Afiliados">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          heading="Afiliados"
          links={[{ name: "Afiliados" }, { name: "Lista" }]}
          action={
            [
              // <Tooltip key="download" title="Download da lista de Afiliados" sx={{ mr: 2 }}>
              //    <IconButton onClick={handleDownloadList}>
              //       {isDownloading ? (
              //          <CircularProgress size={24} color="inherit" />
              //       ) : (
              //          <Iconify icon={'eva:download-outline'} />
              //       )}
              //    </IconButton>
              // </Tooltip>
            ]
          }
        />

        <Card>
          {/* <Tabs
                  allowScrollButtonsMobile
                  variant="scrollable"
                  scrollButtons="auto"
                  value={filterStatus}
                  onChange={onChangeFilterStatus}
                  sx={{ px: 2, bgcolor: 'background.neutral' }}
               >
                  {STATUS_OPTIONS.map((tab) => (
                     <Tab disableRipple key={tab} label={tab} value={tab} />
                  ))}
               </Tabs> */}

          {/* <Divider /> */}

          {isDownloading && <DialogDownloading />}

          <AffiliateTableToolbar
            filters={filters}
            onFilterName={handleFilterName}
            onFilterCpf={handleFilterCpf}
            onFilterPhone={handleFilterPhone}
            onFilterAffiliateCode={handleFilterAffiliateCode}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: "relative" }}>
              {/* {selected.length > 0 && (
                        <TableSelectedActions
                           dense={dense}
                           numSelected={selected.length}
                           rowCount={tableData.length}
                           onSelectAllRows={(checked) =>
                              onSelectAllRows(
                                 checked,
                                 tableData.map((row) => row.id)
                              )
                           }
                           actions={
                              <Tooltip title="Deletar">
                                 <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                                    <Iconify icon={'eva:trash-2-outline'} />
                                 </IconButton>
                              </Tooltip>
                           }
                        />
                     )} */}

              <Table size={dense ? "small" : "medium"}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  // numSelected={selected.length}
                  onSort={onSort}
                  // onSelectAllRows={(checked) =>
                  //    onSelectAllRows(
                  //       checked,
                  //       tableData.map((row) => row.id)
                  //    )
                  // }
                />

                <TableBody>
                  {tableData.map((row) => (
                    <AffiliateTableRow
                      key={row._id}
                      row={row}
                      selected={selected.includes(row._id)}
                      onSelectRow={() => onSelectRow(row._id)}
                      onEditRow={() => handleEditRow(row._id)}
                    />
                  ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                  />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: "relative" }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={tableData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            {/* <FormControlLabel
                     control={<Switch checked={dense} onChange={onChangeDense} />}
                     label="Dense"
                     sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                  /> */}
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ tableData, comparator, filterName, filterStatus, filterRole }) {
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter(
      (item) => item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterStatus !== "todos") {
    tableData = tableData.filter((item) => item.status === filterStatus);
  }

  if (filterRole !== "todos") {
    tableData = tableData.filter((item) =>
      filterRole === "assinante" ? item.role === "subscriber" : item.role === filterRole
    );
  }

  return tableData;
}
